import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
// import { Link, graphql } from "gatsby"
// import Swiper from "@components/swiper/swiper"
// import Hero from "@components/hero"

import FacultyHero from "../images/facultyHero.jpg"
import heller from "../images/heller.jpg"
import kammer from "../images/kammer.jpg"
import klien from "../images/klien.jpeg"
// import WebriQForm from "@webriq/gatsby-webriq-form"

const FacultyPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Faculty" description="" />
    <div
      class="page-headline"
      style={{ backgroundImage: `url(${FacultyHero})` }}
    >
      <div class="container">
        <div class="section-heading text-center">
          <h5 class="font-weight-bold text-uppercase text-orange flair mb-0">
            Faculty
          </h5>
          <h1>NARM Training Institute Faculty</h1>
        </div>
      </div>
    </div>
    <div class="page-content faculty-wrap bg-light">
      <div class="container">
        <div className="row py-5">
          <div className="col-md-4 offset-1 col-sm-12 text-center align-self-center">
            <div className="fac-img">
              <img
                src={heller}
                alt="Heller"
                className="img-fluid"
                style={{ width: "400px" }}
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12 py-3">
            <h3>Dr. Laurence Heller</h3>
            <p className="position">
              <em>NARM Creator & Senior Faculty</em>
            </p>
            <p className=" mb-3">
              <a href="http://drlaurenceheller.com/" target="_blank">
                Dr. Laurence Heller{" "}
              </a>{" "}
              is the creator of the NeuroAffective Relational Model (NARM),
              Senior Faculty member, International Trainer, and co-author of
              <a
                href="https://www.amazon.com/gp/product/1583944893/ref=pd_lpo_sbs_dp_ss_2?pf_rd_p=1944687642&pf_rd_s=lpo-top-stripe-1&pf_rd_t=201&pf_rd_i=1556433727&pf_rd_m=ATVPDKIKX0DER&pf_rd_r=07SW4V25X3YZ142QFFJB"
                target="_blank"
              >
                {" "}
                Healing Developmental Trauma: How Early Trauma Affects
                Self-Regulation, Self-Image and the Capacity for Relationship{" "}
              </a>{" "}
              and
              <a
                href="https://www.amazon.com/Crash-Course-Self-Healing-Accident-Recovery/dp/1556433727"
                target="_blank"
              >
                {" "}
                Crash Course: A Self-Healing Guide to Auto Accident Trauma and
                Recovery
              </a>
              .
            </p>
            <p className="mb-1">
              Please Visit:{" "}
              <a href="http://www.drlaurenceheller.com/" target="_blank">
                {" "}
                drlaurenceheller.com
              </a>
            </p>
            <p>
              Email:{" "}
              <a href="mailto:dr.heller@narmtraining.com">
                dr.heller@narmtraining.com
              </a>
            </p>
          </div>
        </div>
        <div className="row py-5">
          <div className="col-md-4 offset-1 col-sm-12 text-center align-self-center">
            <div className="fac-img">
              <img
                src={kammer}
                alt="Heller"
                className="img-fluid"
                style={{ width: "400px" }}
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12 py-3">
            <h3>Brad Kammer</h3>
            <p className="position">
              <em>NARM Faculty Trainer & NARM Training Director</em>
            </p>
            <p className="">
              <a href="http://www.body-mindtherapy.com/" target="_blank">
                Brad Kammer, LMFT, LPCC, SEP, NMT{" "}
              </a>{" "}
              is a Licensed Marriage & Family Therapist and Professional
              Clinical Counselor. Brad is trained as a Somatic Psychotherapist
              and has studied under and taught with his mentor Dr. Laurence
              Heller for many years, and is deeply inspired by the
              NeuroAffective Relational Model (NARM). Brad is a NARM Master
              Therapist, Consultant, and Faculty Trainer, teaching NARM and
              working with Complex Trauma internationally. Brad is also a
              Somatic Experiencing (SE) Practitioner, Consultant and Faculty
              Trainer, and has been involved in bringing SE to various
              communities around the world. Brad began his career as a
              Humanitarian Aid Worker in Asia which introduced him to personal
              and collective trauma. He became passionate about supporting
              individuals and communities in the transformation of trauma. Brad
              has since focused his work on the integration of Somatic
              Psychology, Interpersonal Neurobiology, and wisdom from Spiritual
              Traditions and Traditional Cultures. Brad lives in a small town in
              Northern California with his family where he is a Somatic
              Psychotherapist, College Professor, Trauma Consultant, and
              Community Educator on Body-Mind approaches to Stress, Trauma &
              Self-Care.
            </p>
            <p className="mb-1">
              Please Visit:{" "}
              <a href="http://www.body-mindtherapy.com/" target="_blank">
                {" "}
                body-mindtherapy.com
              </a>
            </p>
            <p>
              Email:{" "}
              <a href="mailto:bradkammer@narmtraining.com">
                bradkammer@narmtraining.com
              </a>
            </p>
          </div>
        </div>
        <div className="row py-5">
          <div className="col-md-4 offset-1 col-sm-12 text-center">
            <div className="fac-img">
              <img
                src={klien}
                alt="Heller"
                className="img-fluid"
                style={{ width: "400px" }}
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <h3>Stefanie Klein</h3>
            <p className="position">
              <em>NARM Faculty Trainer & NARM Training Assistant Director</em>
            </p>
            <p className="">
              <a
                href="https://www.stefaniekleinlcsw.com/"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Stefanie Klein{" "}
              </a>{" "}
              is a Licensed Clinical Social Worker, Somatic Experiencing
              Practitioner, NARM Master Therapist, consultant, and faculty
              member. She has spent 22 years in her Los Angeles private practice
              specializing in treating adults with trauma and anxiety disorders.
              For the past decade, she has had the distinct privilege of being
              mentored by Dr. Laurence Heller, founder of The Neuroaffective
              Relational Model. Stefanie has been honored to assist Dr. Heller
              in all of his US NARM practitioner trainings and has found NARM to
              be an extremely effective model for working with attachment,
              relational, and developmental trauma. She has also witnessed
              first-hand how personally and professionally transformational the
              NARM Practitioner Training has been for those whom have
              participated thus far. Stefanie is excited to share the NARM
              approach with anyone looking to have more ease and efficacy in
              their practice.
            </p>
            <p className="mb-1">
              Please Visit:{" "}
              <a href="https://www.stefaniekleinlcsw.com/" target="_blank">
                {" "}
                https://www.stefaniekleinlcsw.com/
              </a>
            </p>
            <p>
              Email:{" "}
              <a href="mailto:stefanieklein@narmtraining.com">
                stefanieklein@narmtraining.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default FacultyPage
